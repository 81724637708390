import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './config/plugins'
import VueLazyload from 'vue-lazyload'
import echarts from 'echarts'
import 'echarts/map/js/china'
import './utils/directives.js'
import cal from './utils/calculation';
import socket from './assets/js/mysocket'
import AMap from 'vue-amap';
import vueEsign from 'vue-esign'
import WebIM from './utils/WebIM';
// import "video.js/dist/video-js.css"; // 引入video.js的css
// import hls from "videojs-contrib-hls"; // 播放hls流需要的插件

Vue.prototype.sendMsg = socket.sendMsg
Vue.prototype.cal = cal //小数点精度丢失处理 
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

Vue.use(AMap);
Vue.use(vueEsign);
// Vue.use(hls);
AMap.initAMapApiLoader({
  // 高德key
   key:'8e1110973b41c7dcb00c7c0bf8f797b2',
 // key: '01b48c4c3849357936137213bdd8ea42',
 // key:'284183fa0503d47fdec4855c8a0fabcf',
  //key:'d4c6187f4bd60be280c27489ded5fc41',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
    'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.PolygonEditor',
		'AMap.Geocoder','AMap.Driving','AMap.MoveAnimation','AMap.GraspRoad'
  ],
  v: '2.0'
});

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/404.jpg'),
  loading: require('./assets/loading.svg'),
  attempt: 1,
  listenEvents: ['scroll']
})

Vue.prototype.AMapObj=AMap;
Vue.prototype.$envobj = new Vue();



new Vue({
  router,
  store,
	WebIM,
  data: function() {
    return {
      lingdang: 0,
      lingdangAudio: 'https://kyd.oss.livingtrip.uyl.cn/27d3975776efb628beb13307bbc359e3.mp4',//推送异常
      lingTiShiAudio: 'http://kyd.oss.livingtrip.uyl.cn/d5d2f4263c75b863418d4fe4a461fbb9.mp4',//模糊订单推送
      InterveneAudio: 'http://kyd.oss.livingtrip.uyl.cn/a928322c5c692b351e6036778f5ef2ac.mp4',    //有订单异订单未点链接推送
      InterveneAudios: 'http://kyd.oss.livingtrip.uyl.cn/e161c39e492726d1ac01209c18c1de44.mp4',  //空驶补偿
      subscribe: 'http://kyd.oss.livingtrip.uyl.cn/cf1caf4a6bf1fbc84f338870df824043.mp4',  //预约单提示
      orderyc:'http://kyd.oss.livingtrip.uyl.cn/a289076dc266283970315a63b9567655.mp4',//订单异常
      postData:{}
    }
  },
  render: h => h(App)
}).$mount('#app');

import PublicCss from './css/main.css'
Vue.use(PublicCss)
